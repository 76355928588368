import {Injectable} from '@angular/core';
import {ItemNavigation, NAV_KEY} from '../../models/technique/item-navigation';
import {Router} from '@angular/router';
import {UtilsService} from '../../utils/utils.service';
import {MenuItem} from 'primeng/api';
import {RoutemapService} from '../routemap.service';
import {
  HAS_ADMINISTRATION,
  HAS_FACTURATION,
  HAS_GESTION_IDISTRI,
  HAS_GESTION_PLC,
  HAS_GESTION_STOCKS,
  HAS_GESTION_UDP,
  HAS_GESTIONBESOIN,
  HAS_GESTIONCLIENTS,
  HAS_GESTIONCOMMANDES,
  HAS_GESTIONFOURNISSEUR,
  HAS_GESTIONGEMRCN,
  HAS_GESTIONMARCHE,
  HAS_GESTIONMENU,
  HAS_GESTIONPRODUCTION,
  HAS_GESTIONPRODUITS,
  HAS_GESTIONPRODUITS_IAPPRO,
  HAS_IAPPRO_ADMIN,
  HAS_MONBUREAU,
  HAS_PMS,
  HAS_PORTAIL_ADMIN,
  HAS_PORTAIL_USER
} from '../../constants';
import {Auth2Service} from '../security/auth2.service';
import {Utilisateur} from '../../utilisateur';
import {cloneDeep as _cloneDeep} from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemNavigationService {

  private items: ItemNavigation[];

  private subjectPrimaryRoute = new BehaviorSubject(undefined);
  primaryRoute$ = this.subjectPrimaryRoute.asObservable();

  private subjectSecondaryRoute = new BehaviorSubject(undefined);
  secondaryRoute$ = this.subjectSecondaryRoute.asObservable();


  selectedMenuItem: MenuItem;
  selectedFlMenuItem: MenuItem;
  newFlMenuItem: MenuItem;
  menuItems: MenuItem[] = [];

  hasGestionIDistri$: Observable<boolean>;

  constructor(private router: Router, public utils: UtilsService, private routeMapSvc: RoutemapService, private auth2Svc: Auth2Service) {
    this.items = this.initItems();
    this.menuItems = this.getMenuItems(this.items, this.auth2Svc.utilisateur);

    // on observe le changement d'utilisateur pour verifier les menus à afficher (droits utilisateurs)
    this.userSubscription();

  }

  /**
   * on observe le changement d'utilisateur pour verifier les menus à afficher (droits utilisateurs)
   */
  userSubscription() {
    this.auth2Svc.user$.subscribe(response => {
      this.menuItems = this.getMenuItems(this.items, this.auth2Svc.utilisateur);
    });
  }

  /**
   * Paramétrer les éléments du menu. <br>
   *     LIMITÉ À 3 NIVEAUX<br>
   *         C'est ici, que l'on rajoute de nouvelles entrées au menu
   */
  private initItems(): ItemNavigation[] {

    const items: ItemNavigation[] = [

      {
        key: NAV_KEY.MON_BUREAU,
        breadcrumb: [NAV_KEY.MON_BUREAU],
        icon: 'fas fa-home',
        label: NAV_KEY.MON_BUREAU,
        routerLink: ['/monbureau'],
        userHasRight: HAS_MONBUREAU
      },
      {
        key: NAV_KEY.REFERENTIEL,
        breadcrumb: [NAV_KEY.REFERENTIEL],
        icon: 'fas fa-wrench',
        label: NAV_KEY.REFERENTIEL,
        userHasRight: HAS_ADMINISTRATION,
        children: [
          {
            key: NAV_KEY.EQUILIBRE_ALIMENTAIRE,
            breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.EQUILIBRE_ALIMENTAIRE],
            icon: '',
            label: NAV_KEY.EQUILIBRE_ALIMENTAIRE,
            userHasRight: HAS_ADMINISTRATION,
            children: [
              {
                key: NAV_KEY.FAMILLES_GEMRCN,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.EQUILIBRE_ALIMENTAIRE, NAV_KEY.FAMILLES_GEMRCN],
                icon: '',
                label: NAV_KEY.FAMILLES_GEMRCN,
                routerLink: ['/administration/features/familles-gemrcn'],
                userHasRight: HAS_ADMINISTRATION,
              },
              {
                key: NAV_KEY.REGIMES_ALIMENTAIRES,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.EQUILIBRE_ALIMENTAIRE, NAV_KEY.REGIMES_ALIMENTAIRES],
                icon: '',
                label: NAV_KEY.REGIMES_ALIMENTAIRES,
                routerLink: ['/administration/features/regimes-alimentaires'],
                userHasRight: HAS_ADMINISTRATION,
              },
            ]
          },
          {
            key: NAV_KEY.GESTION_DES_UTILISATEURS,
            breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.GESTION_DES_UTILISATEURS],
            icon: '',
            label: NAV_KEY.GESTION_DES_UTILISATEURS,
            userHasRight: HAS_ADMINISTRATION || HAS_PORTAIL_ADMIN,
            children: [
              {
                key: NAV_KEY.SITES,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.GESTION_DES_UTILISATEURS, NAV_KEY.SITES],
                icon: 'fas fa-map-marker',
                label: NAV_KEY.SITES,
                routerLink: ['/administration/features/sites'],
                userHasRight: HAS_ADMINISTRATION,
              },
              {
                key: NAV_KEY.ENVIRONNEMENTS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.GESTION_DES_UTILISATEURS, NAV_KEY.ENVIRONNEMENTS],
                icon: 'fas fa-sitemap',
                label: NAV_KEY.ENVIRONNEMENTS,
                routerLink: ['/administration/features/environnements'],
                userHasRight: HAS_ADMINISTRATION,
              },
              {
                key: NAV_KEY.ENVIRONNEMENTS_PLC,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.GESTION_DES_UTILISATEURS, NAV_KEY.ENVIRONNEMENTS_PLC],
                icon: 'fas fa-map-marker-alt',
                label: NAV_KEY.ENVIRONNEMENTS_PLC,
                routerLink: ['/administration/features/grid-environnement-plc'],
                userHasRight: HAS_PORTAIL_ADMIN,
              },
              {
                key: NAV_KEY.PROFILS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.GESTION_DES_UTILISATEURS, NAV_KEY.PROFILS],
                icon: 'fas fa-id-card-alt',
                label: NAV_KEY.PROFILS,
                routerLink: ['/administration/features/profils'],
                userHasRight: HAS_ADMINISTRATION,
              },
              {
                key: NAV_KEY.UTILISATEURS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.GESTION_DES_UTILISATEURS, NAV_KEY.UTILISATEURS],
                icon: 'fas fa-user',
                label: NAV_KEY.UTILISATEURS,
                routerLink: ['/administration/features/utilisateurs'],
                userHasRight: HAS_ADMINISTRATION,
              },
              {
                key: NAV_KEY.CONNEXIONS_UTILISATEURS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.GESTION_DES_UTILISATEURS, NAV_KEY.CONNEXIONS_UTILISATEURS],
                icon: 'fas fa-link',
                label: NAV_KEY.CONNEXIONS_UTILISATEURS,
                routerLink: ['/administration/features/utilisateursconnexions'],
                userHasRight: HAS_ADMINISTRATION,
              },
            ]
          },
          {
            key: NAV_KEY.REFERENTIEL_PRODUCTION,
            breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.REFERENTIEL_PRODUCTION],
            icon: '',
            label: 'Production',
            userHasRight: HAS_ADMINISTRATION,
            children: [
              {
                key: NAV_KEY.EQUIPES,
                breadcrumb: [NAV_KEY.REFERENTIEL, 'Production', NAV_KEY.EQUIPES],
                icon: 'fas fa-users',
                label: NAV_KEY.EQUIPES,
                routerLink: ['/administration/features/equipes'],
                userHasRight: HAS_ADMINISTRATION,
              },
              {
                key: NAV_KEY.SECTEURS,
                breadcrumb: [NAV_KEY.REFERENTIEL, 'Production', NAV_KEY.SECTEURS],
                icon: 'fas fa-map',
                label: NAV_KEY.SECTEURS,
                routerLink: ['/administration/features/secteurs'],
                userHasRight: HAS_ADMINISTRATION,
              },
              {
                key: NAV_KEY.ATELIERS,
                breadcrumb: [NAV_KEY.REFERENTIEL, 'Production', NAV_KEY.ATELIERS],
                icon: 'fas fa-home',
                label: NAV_KEY.ATELIERS,
                routerLink: ['/administration/features/zones-de-production'],
                userHasRight: HAS_ADMINISTRATION,
              },
              {
                key: NAV_KEY.ZONES_DE_STOCKAGE,
                breadcrumb: [NAV_KEY.REFERENTIEL, 'Production', NAV_KEY.ZONES_DE_STOCKAGE],
                icon: 'fas fa-home',
                label: NAV_KEY.ZONES_DE_STOCKAGE,
                routerLink: ['/administration/features/zones-de-stockage'],
                userHasRight: HAS_ADMINISTRATION,
              },
            ]
          },
          {
            key: NAV_KEY.RECEPTION,
            breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.RECEPTION],
            icon: '',
            label: NAV_KEY.RECEPTION,

            userHasRight: HAS_ADMINISTRATION,
            children: [
              {
                key: NAV_KEY.LITIGES,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.RECEPTION, NAV_KEY.LITIGES],
                icon: '',
                label: NAV_KEY.LITIGES,
                routerLink: ['/administration/features/litiges'],
                userHasRight: HAS_ADMINISTRATION,
              },
              {
                key: NAV_KEY.MOTIFS_DE_NON_CONFORMITE,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.RECEPTION, NAV_KEY.MOTIFS_DE_NON_CONFORMITE],
                icon: '',
                label: NAV_KEY.MOTIFS_DE_NON_CONFORMITE,
                routerLink: ['/administration/features/motifs-non-conformite'],
                userHasRight: HAS_ADMINISTRATION,
              }
            ]
          },
          {
            key: NAV_KEY.STOCKS,
            breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.STOCKS],
            icon: '',
            label: NAV_KEY.STOCKS,
            userHasRight: HAS_ADMINISTRATION && HAS_GESTION_STOCKS,
            children: [
              {
                key: NAV_KEY.STOCKS_MOUVEMENTS_STATUTS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.STOCKS, NAV_KEY.STOCKS_MOUVEMENTS_STATUTS],
                icon: '',
                label: NAV_KEY.STOCKS_MOUVEMENTS_STATUTS,
                routerLink: ['/administration/features/stocks-mouvements-statuts'],
                userHasRight: HAS_ADMINISTRATION && HAS_GESTION_STOCKS,
              }
            ]
          },
          {
            key: NAV_KEY.REFERENTIEL_DE_BASE,
            breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.REFERENTIEL_DE_BASE],
            icon: '',
            label: NAV_KEY.REFERENTIEL_DE_BASE,
            userHasRight: HAS_ADMINISTRATION,
            children: [
              {
                key: NAV_KEY.ALLERGENES,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.REFERENTIEL_DE_BASE, NAV_KEY.ALLERGENES],
                icon: '',
                label: NAV_KEY.ALLERGENES,
                routerLink: ['/administration/features/allergenes'],
                userHasRight: HAS_ADMINISTRATION,
              },
              {
                key: NAV_KEY.APPELLATIONS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.REFERENTIEL_DE_BASE, NAV_KEY.APPELLATIONS],
                icon: '',
                label: NAV_KEY.APPELLATIONS,
                routerLink: ['/administration/features/appellations'],
                userHasRight: HAS_ADMINISTRATION,
              },
              {
                key: NAV_KEY.PRESTATIONS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.REFERENTIEL_DE_BASE, NAV_KEY.PRESTATIONS],
                icon: '',
                label: NAV_KEY.PRESTATIONS,
                routerLink: ['/administration/features/convives'],
                userHasRight: HAS_ADMINISTRATION,
              },
              {
                key: NAV_KEY.COMPOSANTES_REPAS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.REFERENTIEL_DE_BASE, NAV_KEY.COMPOSANTES_REPAS],
                icon: '',
                label: NAV_KEY.COMPOSANTES_REPAS,
                routerLink: ['/administration/features/decoupages-repas'],
                userHasRight: HAS_ADMINISTRATION,
              },
              {
                key: NAV_KEY.DECLINAISONS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.REFERENTIEL_DE_BASE, NAV_KEY.DECLINAISONS],
                icon: '',
                label: NAV_KEY.DECLINAISONS,
                routerLink: ['/administration/features/declinaisons'],
                userHasRight: HAS_ADMINISTRATION,
              },
              {
                key: NAV_KEY.FAMILLES_PRODUITS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.REFERENTIEL_DE_BASE, NAV_KEY.FAMILLES_PRODUITS],
                icon: '',
                label: NAV_KEY.FAMILLES_PRODUITS,
                routerLink: ['/administration/features/familles-produit'],
                userHasRight: HAS_ADMINISTRATION,
              },
              {
                key: NAV_KEY.REPAS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.REFERENTIEL_DE_BASE, NAV_KEY.REPAS],
                icon: '',
                label: NAV_KEY.REPAS,
                routerLink: ['/administration/features/repas'],
                userHasRight: HAS_ADMINISTRATION,
              },

              {
                key: NAV_KEY.ORIGINES,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.REFERENTIEL_DE_BASE, NAV_KEY.ORIGINES],
                icon: '',
                label: NAV_KEY.ORIGINES,
                routerLink: ['/administration/features/origines'],
                userHasRight: HAS_ADMINISTRATION,
              },
              {
                key: NAV_KEY.TAXES,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.REFERENTIEL_DE_BASE, NAV_KEY.TAXES],
                icon: '',
                label: NAV_KEY.TAXES,
                routerLink: ['/administration/features/taxes'],
                userHasRight: HAS_ADMINISTRATION,
              },
              {
                key: NAV_KEY.TACHES,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.REFERENTIEL_DE_BASE, NAV_KEY.TACHES],
                icon: '',
                label: NAV_KEY.TACHES,
                routerLink: ['/administration/features/taches'],
                userHasRight: HAS_ADMINISTRATION,
              },
              {
                key: NAV_KEY.TYPES_DE_PRODUITS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.REFERENTIEL_DE_BASE, NAV_KEY.TYPES_DE_PRODUITS],
                icon: '',
                label: NAV_KEY.TYPES_DE_PRODUITS,
                routerLink: ['/administration/features/types-produit'],
                userHasRight: HAS_ADMINISTRATION,
              },
              {
                key: NAV_KEY.UNITES_DE_MESURES,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.REFERENTIEL_DE_BASE, NAV_KEY.UNITES_DE_MESURES],
                icon: '',
                label: NAV_KEY.UNITES_DE_MESURES,
                routerLink: ['/administration/features/unites-de-mesures'],
                userHasRight: HAS_ADMINISTRATION,
              },
            ]
          },
          {
            key: NAV_KEY.CONDITIONNEMENTS,
            breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.CONDITIONNEMENTS],
            icon: '',
            label: NAV_KEY.CONDITIONNEMENTS,
            userHasRight: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
            children: [
              {
                key: NAV_KEY.CONDITIONNEMENTS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.CONDITIONNEMENTS, NAV_KEY.CONDITIONNEMENTS],
                icon: '',
                label: NAV_KEY.CONDITIONNEMENTS,
                routerLink: ['/administration/features/conditionnements'],
                userHasRight: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
              },
              {
                key: NAV_KEY.VARIANTES,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.CONDITIONNEMENTS, NAV_KEY.VARIANTES],
                icon: '',
                label: NAV_KEY.VARIANTES,
                routerLink: ['/administration/features/variantes'],
                userHasRight: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
              },
              {
                key: NAV_KEY.CONDITIONNEMENTS_VARIANTES,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.CONDITIONNEMENTS, NAV_KEY.CONDITIONNEMENTS_VARIANTES],
                icon: '',
                label: NAV_KEY.CONDITIONNEMENTS_VARIANTES,
                routerLink: ['/administration/features/conditionnements-variantes'],
                userHasRight: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
              }
            ]
          },
          {
            key: NAV_KEY.PMS,
            breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.REFERENTIEL_DE_BASE, NAV_KEY.PMS],
            icon: '',
            label: NAV_KEY.PMS,
            userHasRight: HAS_ADMINISTRATION && HAS_PMS,
            children: [


              {
                key: NAV_KEY.EQUIPEMENTS_PMS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.PMS, NAV_KEY.EQUIPEMENTS_PMS],
                icon: 'fas fa-cogs',
                label: NAV_KEY.EQUIPEMENTS_PMS,
                routerLink: ['/administration/features/equipements-pms'],
                userHasRight: HAS_ADMINISTRATION && HAS_PMS
              },
              {
                key: NAV_KEY.EQUIPEMENTS_A_NETTOYER_PMS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.PMS, NAV_KEY.EQUIPEMENTS_A_NETTOYER_PMS],
                icon: 'fas fa-broom',
                label: NAV_KEY.EQUIPEMENTS_A_NETTOYER_PMS,
                routerLink: ['/administration/features/equipements-a-nettoyer-pms'],
                userHasRight: HAS_ADMINISTRATION && HAS_PMS
              },
              {
                key: NAV_KEY.SEUILS_PMS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.PMS, NAV_KEY.SEUILS_PMS],
                icon: 'fas fa-greater-than-equal',
                label: NAV_KEY.SEUILS_PMS,
                routerLink: ['/administration/features/seuils-pms'],
                userHasRight: HAS_ADMINISTRATION && HAS_PMS
              },
              {
                key: NAV_KEY.SIGNATURES_UTILISATEUR_PMS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.PMS, NAV_KEY.SIGNATURES_UTILISATEUR_PMS],
                icon: 'fas fa-users',
                label: NAV_KEY.SIGNATURES_UTILISATEUR_PMS,
                routerLink: ['/administration/features/signatures-utilisateur-pms'],
                userHasRight: HAS_ADMINISTRATION && HAS_PMS
              },
              {
                key: NAV_KEY.TACHES_PMS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.PMS, NAV_KEY.TACHES_PMS],
                icon: 'fas fa-tasks',
                label: NAV_KEY.TACHES_PMS,
                routerLink: ['/administration/features/taches-pms'],
                userHasRight: HAS_ADMINISTRATION && HAS_PMS
              },
              {
                key: NAV_KEY.TYPES_EQUIPEMENT_PMS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.PMS, NAV_KEY.TYPES_EQUIPEMENT_PMS],
                icon: 'fas fa-boxes',
                label: NAV_KEY.TYPES_EQUIPEMENT_PMS,
                routerLink: ['/administration/features/types-equipement-pms'],
                userHasRight: HAS_ADMINISTRATION && HAS_PMS
              },
              {
                key: NAV_KEY.TYPES_TACHE_PMS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.PMS, NAV_KEY.TYPES_TACHE_PMS],
                icon: 'far fa-flag',
                label: NAV_KEY.TYPES_TACHE_PMS,
                routerLink: ['/administration/features/types-tache-pms'],
                userHasRight: HAS_ADMINISTRATION && HAS_PMS
              },
              {
                key: NAV_KEY.TYPES_UTILISATEUR_PMS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.PMS, NAV_KEY.TYPES_UTILISATEUR_PMS],
                icon: 'fas fa-user-tag',
                label: NAV_KEY.TYPES_UTILISATEUR_PMS,
                routerLink: ['/administration/features/types-utilisateur-pms'],
                userHasRight: HAS_ADMINISTRATION && HAS_PMS
              },
              {
                key: NAV_KEY.UNITES_PMS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.PMS, NAV_KEY.UNITES_PMS],
                icon: 'fas fa-hand-sparkles',
                label: NAV_KEY.UNITES_PMS,
                routerLink: ['/administration/features/unites-pms'],
                userHasRight: HAS_ADMINISTRATION && HAS_PMS
              },
              {
                key: NAV_KEY.ZONES_PMS,
                breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.PMS, NAV_KEY.ZONES_PMS],
                icon: 'fas fa-hand-sparkles',
                label: NAV_KEY.ZONES_PMS,
                routerLink: ['/administration/features/zones-pms'],
                userHasRight: HAS_ADMINISTRATION && HAS_PMS
              }
            ]
          },
          {
            key: NAV_KEY.CONFIGURATION_PORTAIL,
            breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.REFERENTIEL_DE_BASE, NAV_KEY.CONFIGURATION_PORTAIL],
            icon: '',
            label: NAV_KEY.CONFIGURATION_PORTAIL,
            userHasRight: HAS_ADMINISTRATION,
            children:[
              {
                key: NAV_KEY.CONFIGURATION_PORTAIL_PLC,
                breadcrumb: [NAV_KEY.CONFIGURATION_PORTAIL_PLC],
                icon: 'fas fa-cogs',
                label: NAV_KEY.CONFIGURATION_PORTAIL_PLC,
                routerLink: ['/administration/features/configuration-portail-plc'],
                userHasRight: HAS_ADMINISTRATION
              }
            ]
          },
          {
            key: NAV_KEY.PARAMETRES,
            breadcrumb: [NAV_KEY.REFERENTIEL, NAV_KEY.PARAMETRES],
            icon: 'fas fa-cog',
            label: NAV_KEY.PARAMETRES,
            routerLink: ['/administration/features/parametres'],
            userHasRight: HAS_ADMINISTRATION,
          },
        ]
      },
      {
        key: NAV_KEY.FOURNISSEURS,
        breadcrumb: [NAV_KEY.FOURNISSEURS],
        icon: 'fas fa-truck',
        label: NAV_KEY.FOURNISSEURS,
        userHasRight: HAS_GESTIONFOURNISSEUR,
        children: [
          {
            key: NAV_KEY.LISTE_DES_FOURNISSEURS,
            breadcrumb: [NAV_KEY.FOURNISSEURS, NAV_KEY.LISTE_DES_FOURNISSEURS],
            icon: '',
            label: NAV_KEY.LISTE_DES_FOURNISSEURS,
            routerLink: ['/gestion-fournisseurs'],
            userHasRight: HAS_GESTIONFOURNISSEUR,
          },
          {
            key: NAV_KEY.GROUPE_ACHAT_LIST,
            breadcrumb: [NAV_KEY.FOURNISSEURS, NAV_KEY.GROUPE_ACHAT_LIST],
            icon: '',
            label: NAV_KEY.GROUPE_ACHAT_LIST,
            routerLink: ['/gestion-groupe-achat'],
            userHasRight: HAS_IAPPRO_ADMIN,
          },
          {
            key: NAV_KEY.MISE_A_JOUR_DES_ARTICLES,
            breadcrumb: [NAV_KEY.FOURNISSEURS, NAV_KEY.MISE_A_JOUR_DES_ARTICLES],
            icon: '',
            label: NAV_KEY.MISE_A_JOUR_DES_ARTICLES,
            routerLink: ['/gestion-fournisseurs/articles/article-affecte'],
            userHasRight: HAS_GESTIONFOURNISSEUR,
          },
          {
            key: NAV_KEY.MARCHES,
            breadcrumb: [NAV_KEY.FOURNISSEURS, NAV_KEY.MARCHES],
            icon: '',
            label: NAV_KEY.MARCHES,
            routerLink: ['/gestion-marche'],
            userHasRight: HAS_GESTIONMARCHE,
          },
        ]
      },
      {
        key: NAV_KEY.UNITES_DE_PRODUCTION,
        breadcrumb: [NAV_KEY.UNITES_DE_PRODUCTION],
        icon: 'fas fa-industry',
        label: NAV_KEY.UNITES_DE_PRODUCTION,
        routerLink: ['/gestion-unitesproduction'],
        userHasRight: HAS_GESTION_UDP,
      },
      {
        key: NAV_KEY.PRODUITS,
        breadcrumb: [NAV_KEY.PRODUITS],
        icon: 'fas fa-carrot',
        label: NAV_KEY.PRODUITS,
        routerLink: ['/gestionproduits'],
        userHasRight: HAS_GESTIONPRODUITS && HAS_GESTIONPRODUITS_IAPPRO
      },
      {
        key: NAV_KEY.MENUS,
        breadcrumb: [NAV_KEY.MENUS],
        icon: 'fas fa-calendar-check',
        label: NAV_KEY.MENUS,
        userHasRight: HAS_GESTIONMENU,
        children: [
          {
            key: NAV_KEY.MODELES_DE_MENUS,
            breadcrumb: [NAV_KEY.MENUS, NAV_KEY.MODELES_DE_MENUS],
            icon: 'fas fa-calendar-minus',
            label: NAV_KEY.MODELES_DE_MENUS,
            routerLink: ['/gestiongemrcn'],
            userHasRight: HAS_GESTIONGEMRCN,
          },
          {
            key: NAV_KEY.OFFRES_ALIMENTAIRES,
            breadcrumb: [NAV_KEY.MENUS, NAV_KEY.OFFRES_ALIMENTAIRES],
            icon: 'fas fa-handshake',
            label: NAV_KEY.OFFRES_ALIMENTAIRES,
            routerLink: ['/gestioncontrats'],
            userHasRight: HAS_GESTIONMENU,
          },
          {
            key: NAV_KEY.PLANNING_DES_MENUS,
            breadcrumb: [NAV_KEY.MENUS, NAV_KEY.PLANNING_DES_MENUS],
            icon: 'fas fa-calendar',
            label: NAV_KEY.PLANNING_DES_MENUS,
            routerLink: ['/gestionmenus'],
            userHasRight: HAS_GESTIONMENU,
          },
        ]
      },
      {
        key: NAV_KEY.CLIENTS,
        breadcrumb: [NAV_KEY.CLIENTS],
        icon: 'fas fa-users',
        label: NAV_KEY.CLIENTS,
        userHasRight: HAS_GESTIONCLIENTS,
        children: [
          {
            key: NAV_KEY.PLANNING_DES_EFFECTIFS,
            breadcrumb: [NAV_KEY.CLIENTS, NAV_KEY.PLANNING_DES_EFFECTIFS],
            icon: '',
            label: NAV_KEY.PLANNING_DES_EFFECTIFS,
            routerLink: ['/gestion-plc/menu-plc'],
            userHasRight: HAS_GESTION_PLC,
          },
          {
            key: NAV_KEY.PORTAIL_SAISI_EFFECTIF,
            breadcrumb: [NAV_KEY.CLIENTS, NAV_KEY.PORTAIL_SAISI_EFFECTIF],
            icon: '',
            label: NAV_KEY.PORTAIL_SAISI_EFFECTIF,
            routerLink: ['/gestion-plc/portail-plc'],
            userHasRight: HAS_PORTAIL_USER,
          },
          {
            key: NAV_KEY.CLIENTS_ET_POINTS_DE_LIVRAISONS,
            breadcrumb: [NAV_KEY.CLIENTS, NAV_KEY.CLIENTS_ET_POINTS_DE_LIVRAISONS],
            icon: '',
            label: NAV_KEY.CLIENTS_ET_POINTS_DE_LIVRAISONS,
            routerLink: ['/gestion-clients'],
            userHasRight: HAS_GESTION_PLC,
          },
        ]
      },
      {
        key: NAV_KEY.BESOINS,
        breadcrumb: [NAV_KEY.BESOINS],
        icon: 'fas fa-box',
        label: NAV_KEY.BESOINS,
        routerLink: ['/gestion-listes-besoins/lb/listes-de-besoins'],
        userHasRight: HAS_GESTIONBESOIN,
      },
      {
        key: NAV_KEY.COMMANDES_FOURNISSEURS,
        breadcrumb: [NAV_KEY.COMMANDES_FOURNISSEURS],
        icon: 'fas fa-shopping-cart',
        label: NAV_KEY.COMMANDES_FOURNISSEURS,

        userHasRight: HAS_GESTIONCOMMANDES,
        children: [
          {
            key: NAV_KEY.COMMANDES_TABLEAU_DE_BORD,
            breadcrumb: [NAV_KEY.COMMANDES_FOURNISSEURS, 'Tableau de bord'],
            icon: 'fas fa-tachometer-alt',
            label: 'Tableau de bord',
            routerLink: ['/gestion-commandes-fournisseurs/bc/tableau-de-bord'],
            userHasRight: HAS_GESTIONCOMMANDES && HAS_ADMINISTRATION,
          },
          {
            key: NAV_KEY.PROPOSITIONS_DE_COMMANDES,
            breadcrumb: [NAV_KEY.COMMANDES_FOURNISSEURS, NAV_KEY.PROPOSITIONS_DE_COMMANDES],
            icon: 'fas fa-file-invoice',
            label: NAV_KEY.PROPOSITIONS_DE_COMMANDES,
            routerLink: ['/gestion-commandes-fournisseurs/bc/propositions'],
            userHasRight: HAS_GESTIONCOMMANDES,
          },
          {
            key: NAV_KEY.COMMANDES_REFUSEES,
            breadcrumb: [NAV_KEY.COMMANDES_FOURNISSEURS, NAV_KEY.COMMANDES_REFUSEES],
            icon: 'fas fa-file-invoice',
            label: NAV_KEY.COMMANDES_REFUSEES,
            routerLink: ['/gestion-commandes-fournisseurs/bc/refusees'],
            userHasRight: HAS_GESTIONCOMMANDES,
          },
          {
            key: NAV_KEY.COMMANDES_A_VALIDER,
            breadcrumb: [NAV_KEY.COMMANDES_FOURNISSEURS, NAV_KEY.COMMANDES_A_VALIDER],
            icon: 'fas fa-file-invoice',
            label: NAV_KEY.COMMANDES_A_VALIDER,
            routerLink: ['/gestion-commandes-fournisseurs/bc/a-valider'],
            userHasRight: HAS_GESTIONCOMMANDES,
          },
          {
            key: NAV_KEY.COMMANDES_A_RECEPTIONNER,
            breadcrumb: [NAV_KEY.COMMANDES_FOURNISSEURS, NAV_KEY.COMMANDES_A_RECEPTIONNER],
            icon: 'fas fa-file-invoice',
            label: NAV_KEY.COMMANDES_A_RECEPTIONNER,
            routerLink: ['/gestion-commandes-fournisseurs/bc/a-receptionner'],
            userHasRight: HAS_GESTIONCOMMANDES && HAS_GESTION_STOCKS,
          },
          {
            key: NAV_KEY.COMMANDES_RECEPTIONNEES,
            breadcrumb: [NAV_KEY.COMMANDES_FOURNISSEURS, NAV_KEY.COMMANDES_RECEPTIONNEES],
            icon: 'fas fa-file-invoice',
            label: NAV_KEY.COMMANDES_RECEPTIONNEES,
            routerLink: ['/gestion-commandes-fournisseurs/bc/receptions'],
            userHasRight: HAS_GESTIONCOMMANDES && HAS_GESTION_STOCKS,
          },
          {
            key: NAV_KEY.COMMANDES_FACTUREES,
            breadcrumb: [NAV_KEY.COMMANDES_FOURNISSEURS, NAV_KEY.COMMANDES_FACTUREES],
            icon: 'fas fa-file-invoice',
            label: NAV_KEY.COMMANDES_FACTUREES,
            routerLink: ['/gestion-commandes-fournisseurs/bc/factures'],
            userHasRight: HAS_GESTIONCOMMANDES && HAS_FACTURATION,
          },
        ]
      },
      {
        key: NAV_KEY.STOCKS,
        breadcrumb: [NAV_KEY.STOCKS],
        icon: 'fas fa-cubes',
        label: NAV_KEY.STOCKS,
        userHasRight: HAS_GESTION_STOCKS,
        children: [
          {
            key: NAV_KEY.MATIERES_PREMIERES,
            breadcrumb: [NAV_KEY.STOCKS, NAV_KEY.MATIERES_PREMIERES],
            icon: '',
            label: NAV_KEY.MATIERES_PREMIERES,
            routerLink: ['/gestion-stocks/stocks/matieres-premieres'],
            userHasRight: HAS_GESTION_STOCKS,
          },
          {
            key: NAV_KEY.MOUVEMENTS,
            breadcrumb: [NAV_KEY.STOCKS, NAV_KEY.MOUVEMENTS],
            icon: '',
            label: NAV_KEY.MOUVEMENTS,
            routerLink: ['/gestion-stocks/mouvements/grille-mouvements'],
            userHasRight: HAS_GESTION_STOCKS,
          },
          {
            key: NAV_KEY.INVENTAIRE,
            breadcrumb: [NAV_KEY.STOCKS, NAV_KEY.INVENTAIRE],
            icon: '',
            label: NAV_KEY.INVENTAIRE,
            routerLink: ['/gestion-stocks/stocks/inventaire'],
            userHasRight: HAS_GESTION_STOCKS,
          },
        ]
      },
      {
        key: NAV_KEY.PRODUCTION,
        breadcrumb: [NAV_KEY.PRODUCTION],
        icon: 'fas fa-newspaper',
        label: NAV_KEY.PRODUCTION,
        routerLink: ['/gestion-production/pp/plans-de-production'],
        userHasRight: HAS_GESTIONPRODUCTION,
      },
      {
        key: NAV_KEY.CONDITIONNEMENTS,
        breadcrumb: [NAV_KEY.CONDITIONNEMENTS],
        icon: 'fas fa-boxes',
        label: NAV_KEY.CONDITIONNEMENTS,
        userHasRight: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
        children: [
          {
            key: NAV_KEY.MODELES_PLATS,
            breadcrumb: [NAV_KEY.CONDITIONNEMENTS, NAV_KEY.MODELES_PLATS],
            icon: '',
            label: NAV_KEY.MODELES_PLATS,
            routerLink: ['/gestion-conditionnements/modeles-plats'],
            userHasRight: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
          },
          {
            key: NAV_KEY.MODELES_PLC,
            breadcrumb: [NAV_KEY.CONDITIONNEMENTS, NAV_KEY.MODELES_PLC],
            icon: '',
            label: NAV_KEY.MODELES_PLC,
            routerLink: ['/gestion-conditionnements/modeles-plc'],
            userHasRight: HAS_ADMINISTRATION && HAS_GESTION_IDISTRI,
          }
        ]
      },
    ];


    return items;

  }

  /**
   * Récuperer tous les elements du menus en filtrant sur les droits utilisateurs.<br>
   *     LIMITÉ À 3 NIVEAUX<br>
   *         Structure pour un menu kjlnhjk
   * @param itemNavigationArr
   * @param user
   * @return {MenuItem[]}
   */
  private getMenuItems(itemNavigationArr: ItemNavigation[], user: Utilisateur): MenuItem[] {

    const menuItems: MenuItem[] = [];

    if (!this.utils.isCollectionNullOrEmpty(itemNavigationArr)) {

      // LEVEL 1
      if (!this.utils.isCollectionNullOrEmpty(itemNavigationArr)) {
        for (const nodeLevel1 of itemNavigationArr) {

          if (this.auth2Svc.hasFeature(user, nodeLevel1.userHasRight)) {

            const mil1: MenuItem = this.createMenuItem(nodeLevel1);
            menuItems.push(mil1);

            // LEVEL 2
            if (!this.utils.isCollectionNullOrEmpty(nodeLevel1.children)) {
              mil1.items = [];
              for (const nodeLevel2 of nodeLevel1.children) {

                if (this.auth2Svc.hasFeature(user, nodeLevel2.userHasRight)) {

                  const mil2: any = this.createMenuItem(nodeLevel2);
                  mil1.items.push(mil2);

                  // LEVEL 3
                  if (!this.utils.isCollectionNullOrEmpty(nodeLevel2.children)) {
                    mil2.items = [];
                    for (const nodeLevel3 of nodeLevel2.children) {

                      if (this.auth2Svc.hasFeature(user, nodeLevel3.userHasRight)) {

                        const mil3: any = this.createMenuItem(nodeLevel3);
                        mil2.items.push(mil3);
                      }
                    }
                  }

                }
              }
            }
          }
        }
      }
    }

    return menuItems;
  }


  private createMenuItem(item: ItemNavigation): any {

    const routerLink = this.utils.isCollectionNullOrEmpty(item.routerLink) ? null : item.routerLink;

    return {
      id: item.key,
      label: item.label,
      icon: item.icon,
      routerLink: routerLink,
      breadcrumb: item.breadcrumb,

    }
  }


  /**
   * Est ce que c'est un menu cliquable
   * @param item
   */
  isLeaf(item: MenuItem) {
    return !this.utils.isNullOrEmpty(item) && this.utils.isCollectionNullOrEmpty(item.items);
  }


  navigateFirstLevel(menuItem: MenuItem) {
    this.router.navigate(menuItem.routerLink);

  }


  selectMenuItem(routeUrl: string): { selectedFlMenuItem: MenuItem, selectedMenuItem: MenuItem } {

    const idxOf = routeUrl.indexOf('(');
    let routerLink = [];
    if (idxOf === -1) {
      routerLink = [routeUrl];
    } else {
      routerLink = [routeUrl.substring(0, routeUrl.indexOf('('))];
    }


    let mi = this.searchMenuItemByRouterLink(routerLink) as any;
    let mifl = null;

    // par defaut, si rien trouvé, on va sur monbureau
    if (this.utils.isNullOrEmpty(mi)) {
      mi = this.searchMenuItemByRouterLink(['/monbureau']);
    }

    for (const menuItem of this.menuItems) {
      const miitem: any = menuItem;
      if (miitem.breadcrumb[0] === mi.breadcrumb[0]) {
        mifl = miitem;
        break;
      }
    }


    return {
      selectedFlMenuItem: mifl,
      selectedMenuItem: mi
    };

  }

  /**
   * BFS search
   * @param routerLink
   */
  private searchMenuItemByRouterLink(routerLink: string[]): MenuItem {

    if (!this.utils.isCollectionNullOrEmpty(routerLink)) {

      const file = _cloneDeep(this.menuItems);

      while (!this.utils.isCollectionNullOrEmpty(file)) {
        const item = file.pop();
        if (item.routerLink && item.routerLink[0] === routerLink[0]) {
          return item;
        }
        while (!this.utils.isCollectionNullOrEmpty(item.items)) {
          for (const itemElement of item.items) {
            file.push(itemElement as any);
          }
          item.items = [];
        }
      }
    }
    return null;
  }


  announcePrimaryRoute(event) {
    this.subjectPrimaryRoute.next(event);
  }

  announceSecondaryRoute(event) {
    this.subjectSecondaryRoute.next(event);
  }

  /**
   * fix Primng detection primary route
   * @param event
   * @param menuItems
   */
  activePrimaryRoute(event, menuItems: MenuItem[]): MenuItem {

    let activeItem = null;

    if (event) {

      const route: string = event.url;
      for (const item of menuItems) {
        if (route.includes(item.routerLink[0])) {
          activeItem = item;
          break;
        }
      }
    }

    return activeItem;
  }

  /**
   * fix Primng detection secondary route
   * @param event
   * @param menuItems
   */
  activeSecondaryRoute(event, menuItems: MenuItem[]): MenuItem {

    let activeItem = null;
    const currentNav = this.router.getCurrentNavigation();

    if (event ) {

      const url = event.snapshot._routerState.url;

      for (const item of menuItems) {
        if (url.toString().includes(item.routerLink[0])) {
          activeItem = item;
          break;
        }
      }
    }

    return activeItem;
  }




}
