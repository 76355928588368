// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[production]4.2.3-8540(8f34f4b6fa)-C17/05/2022-17:28:14-B17/05/2022-18:22:49' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[production]4.2.3-8540(8f34f4b6fa)-C17/05/2022-17:28:14-B17/05/2022-18:22:49",
  branch: "production",
  latestTag: "4.2.3",
  revCount: "8540",
  shortHash: "8f34f4b6fa",
  longHash: "8f34f4b6fad23ea29ed95666c9a3f5572592a2fe",
  dateCommit: "17/05/2022-17:28:14",
  dateBuild: "17/05/2022-18:22:49",
  buildType: "Ansible",
  } ;
