import {Component, OnDestroy, OnInit} from '@angular/core';
import {LotMarcheDTO} from '../../../core/dtos/gerstion-marche/lot-marche-dto';
import {DialogMsgSupplier, Paragraphe} from '../../../core/suppliers/dialog-msg-supplier';
import {UtilsService} from '../../../core/utils/utils.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MarcheSupplier} from '../marche-resolver.service';
import {MarcheDTO} from '../../../core/dtos/gerstion-marche/marche-dto';
import {Subscription} from 'rxjs';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {GestionLotMarcheService} from '../../../core/services/gestion-marche/gestion-lot-marche.service';
import {LotMarcheService} from '../../../core/services/entities/lot-marche.service';
import {FP_ROUTES, HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {GestionsMarcheService, TAB_NAME_M} from '../../../core/services/gestion-marche/gestions-marche.service';
import {ConfirmationService} from 'primeng/api';
import {confirm} from "devextreme/ui/dialog";

@Component({
  selector: 'yo-lots-marche-sidebar',
  templateUrl: './lots-marche.component.html',
  styleUrls: ['./lots-marche.component.scss']
})
export class LotsMarcheComponent implements OnInit, OnDestroy {

  marche: MarcheDTO;
  lotMarcheList: LotMarcheDTO[] = [];

  subscriptionRoute : Subscription;

  pathFile: string = HELP_FOLDERS.MARCHES + '/lots-marche';

  constructor(public utils: UtilsService,
              private route: ActivatedRoute,
              private router: Router,
              private confirmationService :ConfirmationService,
              private gestionMarcheSvc: GestionsMarcheService,
              private gestionLotMarcheSvc :GestionLotMarcheService,
              private lotMarcheSvc: LotMarcheService,
              private auth2Svc :Auth2Service) {
  }

  ngOnInit(): void {
    this.initDataGrid();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
  }

  initDataGrid = () => {
    this.subscriptionRoute = this.route.data
      .subscribe((data: { marcheSupplier: MarcheSupplier }) => {
        this.marche = data.marcheSupplier.marche;
        this.lotMarcheList = data.marcheSupplier.lotMarcheList;
      });
   this.gestionMarcheSvc.announceMarche(this.marche, TAB_NAME_M.lotMarche);
  };

  openEditLotMarche = (lotMarche: LotMarcheDTO) => {
    if(this.utils.isNullOrEmpty(lotMarche)){
      lotMarche = this.lotMarcheSvc.createEmptyDTO();
      lotMarche.id = 0;
    }
    this.router.navigate([{ outlets: { secondary: [FP_ROUTES.GESTION_MARCHE, 'marche', this.marche.id, 'lots-marche', lotMarche.id, 'infos'] } }]);
  };

  canCreate = () => {
    if(this.marche){
      return this.auth2Svc.isSiteLocal(this.marche.site.id)
    }else{
      return false;
    }
  };

  canModify = () => this.auth2Svc.isSiteLocal(this.marche.site.id);

  openDeleteLotMarche = async (lotMarche: LotMarcheDTO) => {
    const res = await confirm(`Etes-vous sûr de vouloir supprimer le lot marché "${lotMarche.libelle} " ?`, "Suppression lot marché");
    if (res) {
      this.gestionLotMarcheSvc.deleteLotMarche(lotMarche.id).subscribe( response =>{
        this.utils.showMsg(MSG_KEY.SIDEBAR,MSG_SEVERITY.SUCCESS,`Suppression réussie`);
        this.lotMarcheList = this.lotMarcheList.filter(lm => lm.id !== lotMarche.id);
      });
    }
  };


  public help = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `Les lots marchés`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    let p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [];
    dms.content = {
      intro: `Ils permettent l'articulation d'un marché autour d'une denrée pour une période négocié avec un ou plusieurs founisseurs.`,
      paragraphes: []
    };
    return dms;
  };
}
