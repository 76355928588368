import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {
  CatalogueAchatDialogSupplier,
  CataloguesAchatsService,
  PROVENANCE
} from '../../../../core/services/entities/catalogues-achats.service';
import {Subscription} from 'rxjs';
import {CatalogueAchatDTO} from '../../../../core/dtos/catalogue-achat-dto';
import {tap} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
import {cloneDeep as _cloneDeep} from 'lodash';
import {GenericFormService} from '../../../../core/services/generics/generic-form.service';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {Router} from '@angular/router';
import {RoutemapService} from '../../../../core/services/routemap.service';
import {MSG_KEY, MSG_POSITION} from '../../../../core/constants';

@Component({
  selector: 'yo-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, OnDestroy {

  displayDialog = false;
  subOpenDialog: Subscription;
  subCloseDialog: Subscription;
  subSavedArticleSubscription: Subscription;
  catalogueAchat: CatalogueAchatDTO;
  cads: CatalogueAchatDialogSupplier;

  MSG_KEY = MSG_KEY;
  MSG_POSITION=MSG_POSITION;


  articleFeatures: MenuItem[] = [];
  activeItem:MenuItem;


  constructor(public utils: UtilsService, private catalogueAchatSvc: CataloguesAchatsService,
              private gfs: GenericFormService,
              private gds: GenericDatagridService,
              private confirmationSvc: ConfirmationService,
              private router: Router,
              private routeMapSvc: RoutemapService,
              public domSanitizer: DomSanitizer) {
  }

  ngOnInit(): void {

    // ouverture du dialog
    this.openDialogSubscription();

    // fermeture du dialog
    this.closeDialogSubscription();

    // enregistrement d'un article (onglet info)
    this.savedArticleSubscription();


  }

  closeDialogSubscription = () => {
    this.subCloseDialog = this.catalogueAchatSvc.closeDialogArticle$.subscribe(response => this.closeDialog());
  };

  openDialogSubscription = () => {

    this.subOpenDialog = this.catalogueAchatSvc.openDialogArticle$
      .pipe(
        tap(data => this.displayDialog = true),
        tap(data => this.cads = data),
        tap(data => this.hideFeatures(this.cads)),
        tap(data => {

                    switch(data.routeProvenance){
            case PROVENANCE.FOURNISSEUR:
              this.router.navigate(data.rootRoute);
              break;
            case PROVENANCE.PRODUIT:
              this.routeMapSvc.goToSecondaryRoute(data.rootRoute);
              break;
          }
        }),
        tap(data => {
          switch (data.route) {
            case 'infos' :
              this.catalogueAchatSvc.announceArticleInfos(data);
              break;
            case 'appellations' :
              this.catalogueAchatSvc.announceArticleAppellations(data);
              break;
            case 'allergenes' :
              this.catalogueAchatSvc.announceArticleAllergenes(data);
              break;
          }
        }),
        tap(data => this.catalogueAchat = _cloneDeep(data.catalogueAchat)))
      .subscribe();
  };

  closeDialog = () => {

    this.displayDialog = false;
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
    this.utils.unsubscribe(this.subCloseDialog);
  }

  getTitle = (catalogueAchat: CatalogueAchatDTO) => {
    if (this.utils.isNullOrEmpty(catalogueAchat)) {
      return '<i class="fas fa-plus mg-r-5"></i>Créer un article';
    } else {
      return `<i class="fas fa-edit mg-r-5" pTooltip="Modifier l'article"></i> ${catalogueAchat.produitArticle.libelle.toUpperCase()} /  ${catalogueAchat.fournisseur.libelle}`;
    }
  };


  /**
   * On cache les appellations et les allergenes si on créé un nouvel article
   * @param cads
   */
  private hideFeatures = (cads: CatalogueAchatDialogSupplier) => {

    const infosItem = {
      label: 'Informations',
      routerLink: cads.routeProvenance === PROVENANCE.FOURNISSEUR? `${cads.produit.id}/infos`:'infos',
      command: (event) => this.catalogueAchatSvc.announceArticleInfos(this.cads)
    };
    const appellationsItem = {
      label: 'Appellations',
      routerLink: cads.routeProvenance === PROVENANCE.FOURNISSEUR? `${cads.produit.id}/appellations`:'appellations',
      command: (event) => this.catalogueAchatSvc.announceArticleAppellations(this.cads)
    };
    const allergenesItem = {
      label: 'Allergènes',
      routerLink: cads.routeProvenance === PROVENANCE.FOURNISSEUR? `${cads.produit.id}/allergenes`:'allergenes',
      command: (event) => this.catalogueAchatSvc.announceArticleAllergenes(this.cads)
    };


    if (cads) {
      if (cads.isNew) {
        this.articleFeatures = [infosItem];
      } else {
        this.articleFeatures = [infosItem, allergenesItem, appellationsItem];
      }
      this.activeItem=this.articleFeatures[0];
    } else {
      this.articleFeatures = [];
    }


  };

  private savedArticleSubscription = () => {

    this.subSavedArticleSubscription = this.catalogueAchatSvc.savedDto$.subscribe(response => {
      if (!this.utils.isResponseSupplierError(response) && !this.utils.isNullOrEmpty(this.cads)) {
        this.cads.isNew = false;
      }
      this.hideFeatures(this.cads);
    });
  };

  getMaxBodyHeight = (cads: CatalogueAchatDialogSupplier) => {

    if (cads) {

      cads.maxBodyHeight = window.innerHeight -160;

      if (cads.maxBodyHeight > cads.bodyHeight) {
        cads.maxBodyHeight = this.cads.bodyHeight;
      }

      return cads.maxBodyHeight;
    }

    return 940;

  };
}
