import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'yo-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit {

  @Input() yoLabel : string;
  @Input() yoFormControl : FormControl;
  @Input() yoMinLength: number;
  @Input() yoMaxLength: number;
  @Input() yoMax: number;
  @Input() yoMin: number;

  constructor() {



  }

  ngOnInit() {}

}
