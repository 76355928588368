<dx-popup
  [width]="900"
  [height]="'auto'"
  [showTitle]="true"
  [title]="dialogTitle"
  [(visible)]="displayDialog"
  [resizeEnabled]="true"
  (onHiding)="closeDialog()"
>
  <form (ngSubmit)="save()" [formGroup]="form">

    <div class="p-2 row mg-t-10">
      <div class="col-md-4 genlabel required">Site <span class="danger-color">*</span> </div>
      <div class="col-md-8">
        <div class="dx-field">
          <dx-lookup
            [value]="siteSelected?.id"
            [items]="sitesList"
            formControlName="site"
            placeholder="Sélectionner un site"
            valueExpr="id"
            displayExpr="libelle"
            searchExpr="libelle"
          >
          </dx-lookup>
        </div>
      </div>
    </div>

    <div class="p-2 row mg-t-10">
      <div class="col-md-4 genlabel required">Libellé <span class="danger-color">*</span> </div>
      <div class="col-md-8">
        <div class="input-group-sm">
          <dx-text-box placeholder="Cuisine" formControlName="libelle"></dx-text-box>
          <ng-container
            *ngTemplateOutlet="tplError;context:{form:form,property:'libelle',label:'Libellé'}"></ng-container>
        </div>
      </div>
    </div>

    <div class="p-2 row mg-t-10">
      <div class="col-md-12 genlabel required">Image</div>

      <div class="col-md-4">
        <dx-file-uploader
          selectButtonText="Choisir un fichier"
          labelText=""
          [id]="field.key"
          (change)="onFileChange($event)"
          accept="image/jpeg, image/png"
          [showFileList]="false"
          uploadMode="useForm"
        >
        </dx-file-uploader>
      </div>
      <div class="col-md-4">
        <ng-container *ngIf="!utils.isNullOrEmpty(previewFile)">
          <div class="col-md-auto">
            <p class="font-12 genlabel">Prévisualisation avant enregistrement</p>
            <img id="previewImage" src="#" height="64" alt="">
          </div>
        </ng-container>
      </div>

      <div class="col-md-4">
        <ng-container *ngIf="!utils.isNullOrEmpty(previewFile)">
          <p class="font-12 genlabel">Image enregistrée</p>
        </ng-container>
        <yo-img-entity *ngIf="!zone || !zone.id"
                       [refresh]="1"
                       [height]="64"
                       format="thumb"
                       [displayNoImage]="true"
                       [entityName]="field.entityName"
                       [ordre]="1"></yo-img-entity>
        <yo-img-entity *ngIf="zone && zone.id"
                       [entityId]="zone.id" [refresh]="1"
                       [height]="64"
                       [openable]="true"
                       format="thumb"
                       [entityName]="field.entityName"
                       [ordre]="1"></yo-img-entity>
      </div>
    </div>

    <div class="p-2 row mg-t-10">
      <div class="col-md-4 genlabel">Equipements à nettoyer <span class="danger-color">*</span></div>

      <div class="col-md-8">
        <div class="dx-field">
          <dx-drop-down-box
            [(value)]="treeBoxValue"
            formControlName="list"
            valueExpr="id"
            displayExpr="libelle"
            placeholder="Sélectionner un ou des équipements à nettoyer"
            [showClearButton]="true"
            [dataSource]="equipements"
            (onValueChanged)="onDropDownBoxValueChanged()"
          >
            <div *dxTemplate="let data of 'content'">
              <dx-tree-view
                [dataSource]="equipements"
                dataStructure="plain"
                selectionMode="multiple"
                showCheckBoxesMode="normal"
                [selectNodesRecursive]="false"
                displayExpr="libelle"
                [selectByClick]="true"
                (onContentReady)="onTreeViewReady($event)"
                (onItemSelectionChanged)="onTreeViewSelectionChanged($event)"
              ></dx-tree-view>
            </div>
          </dx-drop-down-box>
        </div>
      </div>
    </div>

    <div class="p-2 d-flex justify-content-end">
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="!canModify()"
        [class.disable]="!canModify()">
        <i class="fas fa-save"></i> Enregistrer
      </button>
      <button
        class="ml-3 btn btn-secondary"
        type="button"
        (click)="closeDialog()">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </form>
</dx-popup>

<ng-template #tplError let-form="form" let-property="property" let-label="label">
  <div class="error"
       *ngIf="form.controls[property].invalid && (form.controls[property].dirty || form.controls[property].touched )">

    <pre>{{form.controls[property].validators | json}}</pre>

    <div class="pg-2" *ngIf="form.controls[property].hasError('required')">
      Le champ {{label}} est obligatoire.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('minlength')">
      Le champ {{label}} ne peut avoir moins de {{form.controls[property].errors.minlength.requiredLength}} caractères.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('maxlength')">
      Le champ {{label}} ne doit pas dépasser {{form.controls[property].errors.maxlength.requiredLength}} caractères.
    </div>

  </div>

</ng-template>
