import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {FormFieldFileSupplier} from "../../../../core/suppliers/form-field-file-supplier";
import {EquipementANettoyerPmsDto} from "../../../../core/dtos/pms/equipement-a-nettoyer-pms-dto";
import {EquipementANettoyerPmsService} from "../../../../core/services/pms/equipement-a-nettoyer-pms.service";

@Component({
  selector: 'yo-pms-equipement-a-nettoyer-dialog',
  templateUrl: './pms-equipement-a-nettoyer-dialog.component.html',
  styleUrls: ['./pms-equipement-a-nettoyer-dialog.component.scss']
})
export class PmsEquipementANettoyerDialogComponent implements OnInit, OnDestroy {
  hasPms = false;

  displayDialog = false;

  subOpenDialog: Subscription;

  equipementANettoyer: EquipementANettoyerPmsDto;
  forUpdate: boolean = false;

  form: FormGroup = new FormGroup({});
  previewFile: File;
  docError = false;
  field: FormFieldFileSupplier;

  sitesList: SiteDTO[];
  siteSelected: SiteDTO;

  dialogTitle = 'Modification d\'un équipement à nettoyer';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private equipementANettoyerPmsService: EquipementANettoyerPmsService) {
  }

  ngOnInit(): void {
    this.initField();
    this.initForm();
    this.initHasPms();
    this.findAllLocalSites();
    this.openDialogVarianteSubscription();
    if(document.getElementById('previewImage')) {
      document.getElementById('previewImage').setAttribute('src', null);
    }
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
  }

  findAllLocalSites = (): void => {
    this.sitesList = this.auth2Svc.utilisateur.siteListLocaux;
  }

  openDialogVarianteSubscription = (): void => {
    this.subOpenDialog = this.equipementANettoyerPmsService.openDialog$
      .subscribe((e: EquipementANettoyerPmsDto) => {
        this.displayDialog = true;
        this.previewFile = null;
        if (!e) {
          this.forUpdate = false;
          this.equipementANettoyer = new EquipementANettoyerPmsDto();
          this.equipementANettoyer.id = null;
          this.dialogTitle = 'Création d\'un équipement à nettoyer';
        } else {
          this.forUpdate = true;
          this.equipementANettoyer = e;
          this.siteSelected = e.site;
          this.dialogTitle = 'Modification d\'un équipement à nettoyer';

        }

        this.initForm();
      });
  };

  initHasPms = (): void => {
    this.auth2Svc.hasPms$.subscribe(response => this.hasPms = response);
  };

  compare = (data1: any, data2: any): boolean => {
    if (data1 && data2) {
      return data1.id === data2.id;
    }
    return null;
  };

  initField = (): void => {
    /**
     * initialisation de la variable a utilisé pour l'affichage de l'image avec yo-img-entity
     */
    this.field = new FormFieldFileSupplier({
      key: 'file',
      label: 'Logo',
      readonly: !this.canModify(),
      value: '',
      entityName: this.equipementANettoyerPmsService.getEntityName().toLowerCase(),
      required: false,
      refresh: new Date().getTime(),
      ordre: 1
    });
  };

  closeDialog = (): void => {
    this.displayDialog = false;
    this.siteSelected = null;
  };

  canModify = (): boolean => this.equipementANettoyer && this.hasPms;

  /**
   * fonction qui sauvegarde l'équipement à nettoyer pms créé ou modifié
   */
  save = async (): Promise<void> => {
    if (this.form.valid) {
      this.equipementANettoyer.libelle = this.form.controls['libelle'].value;
      this.equipementANettoyer.image = this.form.controls['field'].value;
      this.equipementANettoyer.site = this.sitesList.find(site => site.id === this.form.controls['site'].value);
      this.equipementANettoyerPmsService.save(this.equipementANettoyer, this.equipementANettoyer.image)
        .subscribe(response => {
          this.equipementANettoyerPmsService.announceSaved(response.one, this.equipementANettoyer.id !== undefined && this.equipementANettoyer.id !== null && this.equipementANettoyer.id !== 0);
          this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Sauvegarde de l\'équipement à nettoyer pms réalisée avec succès.');
          this.closeDialog();
          this.initForm();
        });
    } else {
      this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, 'Veuillez compléter le formulaire.');
    }
  };

  /**
   * Méthode qui permet d'initialiser le formulaire pour la création ou la modification d'un équipement à nettoyer pms
   */
  initForm = (): void => {
    this.form = new FormGroup({
      libelle: new FormControl(this.equipementANettoyer ? this.equipementANettoyer.libelle : '', [Validators.required, Validators.minLength(1), Validators.maxLength(25)]),
      field: new FormControl(this.equipementANettoyer ? this.equipementANettoyer.image : ''),
      site: new FormControl(this.equipementANettoyer ? this.equipementANettoyer.site : null, [Validators.required])
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Equipements à nettoyer (Plan de Mesure Sanitaire)`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

  /**
   * Fonction qui permet de prévisualiser l'image téléchargé avant sa sauvegarde
   */
  previewImage = (): void => {
    const reader = new FileReader();
    reader.onload = function (e: any) {
      document.getElementById('previewImage').setAttribute('src', e.target.result);
    }
    reader.readAsDataURL(this.previewFile);
  };

  /**
   * Fonction qui récupère l'image puis qui appelle la fonction previewImage
   * pour prévisualiser celle-ci avant de l'enregistrer
   * @param event
   */
  onFileChange = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.previewFile = event.target.files[0];
      if (!this.utils.isNullOrEmpty(this.previewFile)) {
        // previsualiser l'image avant de l'enregistrer
        this.previewImage();
        this.form.get("field").setValue(this.previewFile);
      }
    }
  };
}
