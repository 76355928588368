import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {GenericManageDialogService} from "../../../../core/services/generics/generic-manage-dialog.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {ConfirmationService} from "primeng/api";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {TypeUtilisateurPmsDto} from "../../../../core/dtos/pms/type-utilisateur-pms-dto";
import {
  TypeUtilisateurPmsSavedSupplier,
  TypeUtilisateurPmsService
} from "../../../../core/services/pms/type-utilisateur-pms.service";
import {InternationalizationService} from "../../../../core/services/i8n/i8n.service";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'yo-pms-type-utilisateur-grille',
  templateUrl: './pms-type-utilisateur-grille.component.html',
  styleUrls: ['./pms-type-utilisateur-grille.component.scss']
})
export class PmsTypeUtilisateurGrilleComponent implements OnInit, OnDestroy {
  typesUtilisateurList: TypeUtilisateurPmsDto[] = [];

  hasPms = false;

  subTypeUtilisateurSaved: Subscription;

  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  pathFile: string = HELP_FOLDERS.PMS + '/pms-types-utilisateurs';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              private genericManageDialogService: GenericManageDialogService,
              public gds: GenericDatagridService,
              private i8nSvc: InternationalizationService,
              private confirmationSvc: ConfirmationService,
              private typeUtilisateurPmsService: TypeUtilisateurPmsService,
              private title: Title) {}

  ngOnInit(): void {
    this.initHasPms();
    this.initTypesUtilisateurPms();
    this.typeUtilisateurSavedSubscription();
    this.initProperty();
  }

  initProperty = (): void => {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
    this.title.setTitle(this.getTitle());
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subTypeUtilisateurSaved);
  }

  getTitle = (): string => "GESTION DES TYPES D'UTILISATEUR";

  canEdit = (): boolean => this.hasPms && this.auth2Svc.hasSitesLocaux();

  initTypesUtilisateurPms = (): void => {
    this.typeUtilisateurPmsService.getAll()
      .subscribe(response => {
        this.typesUtilisateurList = response.resultList;
      });
  };

  initHasPms = (): void => {
    this.auth2Svc.hasPms$.subscribe(response => this.hasPms = response);
  };

  typeUtilisateurSavedSubscription = (): void => {
    this.subTypeUtilisateurSaved = this.typeUtilisateurPmsService.typeUtilisateurSaved$
      .subscribe((response: TypeUtilisateurPmsSavedSupplier) => {
        if (!response.isUpdate) {
          this.typesUtilisateurList.push(response.typeUtilisateur);
        }
      });
  };

  openTypeUtilisateur = (t: TypeUtilisateurPmsDto): void => {
    this.typeUtilisateurPmsService.announceOpenDialog(t);
  };

  deleteValues = (): void => {
    this.confirmationSvc.confirm({
      message: 'Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionnés ?',
      accept: () => {
        const idsToDelete: number[] = this.selectedRows
          .map((idSelected: number) => this.typesUtilisateurList.find(t => t.id === idSelected))
          .map((type: TypeUtilisateurPmsDto) => type.id);
        this.typeUtilisateurPmsService.deleteByIds(idsToDelete)
          .subscribe(o => {
            this.typesUtilisateurList = this.utils.handleIsDeletableResultWithNum(o.one, idsToDelete, this.typesUtilisateurList);
            this.utils.showMsg(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS,  `La suppression a bien été prise en compte`);
          });
      }
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Les types d'utilisateur PMS (Plan de Mesure Sanitaire)`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };
}
