<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <div class="d-flex flex-rowa align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <div class="mg-l-5 mg-r-5">
        <button
          pButton
          class="mg-r-10 cursor"
          icon="fa fa-plus"
          label="CRÉER"
          (click)="openUnite(undefined)"
          [disabled]="!canEdit()"
          pTooltip="Créer une unité">
        </button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="right">
    <button
      pButton
      type="button"
      icon="fas fa-trash"
      (click)="deleteValues()"
      [disabled]="!canEdit() || !selectedRows.length"
      pTooltip="Supprimer les éléments sélectionnés">
    </button>
  </ng-template>
</p-toolbar>

<dx-data-grid
  [dataSource]="unitesList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="id" caption="Actions" [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="site.libelle" caption="Site" [width]="300"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="libelle" caption="Libellé" [width]="350"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="abreviation" caption="Abréviation" [width]="350"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>


  <!--  ACTIONS CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'actionsCellTemplate'">
    <yo-cell-button (yoNavigation)="openUnite(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoMaxWidth]="true"
                    [yoWidthPercent]="30"
                    [yoTextAlign]="'center'"
                    [yoIconClass]="canEdit()?'fa fa-edit':'fas fa-eye'"
                    [pTooltip]="canEdit()?'Modifier l\'unité':'Voir l\'unité'"
                    tooltipPosition="right"
                    showDelay="500">
    </yo-cell-button>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} unité(s)">
  </dxo-pager>

</dx-data-grid>

<yo-pms-unite-dialog></yo-pms-unite-dialog>


